import { configureStore } from '@reduxjs/toolkit';
import RootReducer from './reducers';
import { createLogger } from 'redux-logger';

const loggerMiddleware = createLogger();

export const store = configureStore({
    reducer: RootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(loggerMiddleware)
});

export function getStore() {
    return store;
}