import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';

import App from "./App";
import * as serviceWorker from './serviceWorker';

const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

//ReactDOM.render(<Root />, document.getElementById('root'));
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//serviceWorker.unregister();
serviceWorker.register();
