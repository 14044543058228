export const lifecyclePhases = [
  { "label": "À prospecter", "value": "100800877", "color": "#0000FF", "order": 1 },
  { "label": "Prospecté ✅", "value": "101287087", "color": "#32CD32", "order": 2 },
  { "label": "Lead", "value": "lead", "color": "#00FF00", "order": 3 },
  { "label": "Pas intéressé", "value": "subscriber", "color": "#FF0000", "order": 4 },
  { "label": "Repasser", "value": "53098103", "color": "#FF4500", "order": 5 },
  { "label": "Mauvaise cible", "value": "53120163", "color": "#8B0000", "order": 6 },
  { "label": "Déjà équipé", "value": "60397942", "color": "#8B4513", "order": 7 },
  { "label": "Intéressé", "value": "marketingqualifiedlead", "color": "#FF1493", "order": 8 },
  { "label": "Négociation", "value": "60617581", "color": "#FFD700", "order": 9 },
  { "label": "Carte en attente", "value": "81384565", "color": "#FFA500", "order": 10 },
  { "label": "Accord digitalisation", "value": "opportunity", "color": "#00FFFF", "order": 11 },
  { "label": "Client payant", "value": "customer", "color": "#008000", "order": 12 },
  { "label": "Client freemium", "value": "evangelist", "color": "#ADFF2F", "order": 13 },
  { "label": "Inactif", "value": "81377805", "color": "#000000", "order": 14 },
  { "label": "Désabonné", "value": "salesqualifiedlead", "color": "#8B0000", "order": 15 },
  { "label": "RDV", "value": "53414538", "color": "#1E90FF", "order": 16 },
  { "label": "Plus de Réponse", "value": "77667414", "color": "#808080", "order": 17 },
  { "label": "Pas pris", "value": "79354177", "color": "#FF0000", "order": 18 }
];

export function getColorForPhase(value) {
  const phase = lifecyclePhases.find(p => p.value === value);
  return phase ? phase.color || "#000000" : "#000000";
}

export function getOrderForPhase(value) {
  const phase = lifecyclePhases.find(p => p.value === value);
  return phase ? phase.order : 0;
}

export function getLabelForPhase(value) {
  const phase = lifecyclePhases.find(p => p.value === value);
  return phase ? phase.label : null;
}

export function isNullOrEmpty(value) {
  return value === null || value === undefined || value === '';
}

// columnMapping.js
export const columnMapping = {
  "À prospecter": "date_de_conversion_en_a_prospecter",
  "Prospecté ✅": "date_de_conversion_en_prospecte__",
  "Lead": "date_de_conversion_en_lead",
  "Pas intéressé": "date_de_conversion_en_pas_interesse",
  "Repasser": "date_de_conversion_en_repasser",
  "Mauvaise cible": "date_de_conversion_en_mauvaise_cible",
  "Déjà équipé": "date_de_conversion_en_deja_equipe",
  "Intéressé": "date_de_conversion_en_interesse",
  "Négociation": "date_de_conversion_en_negociation",
  "Carte en attente": "date_de_conversion_en_carte_en_attente",
  "Accord digitalisation": "date_de_conversion_en_accord",
  "Client payant": "date_de_conversion_en_client_premium",
  "Client freemium": "date_de_conversion_en_client_freemium",
  "Inactif": "date_de_conversion_en_inactif",
  "Désabonné": "date_de_conversion_en_desabonne",
  "RDV": "date_de_conversion_en_rdv",
  "Plus de Réponse": "date_de_conversion_en_plus_de_reponse",
  "Pas pris": "date_de_conversion_en_pas_pris"
};

