import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { loading } from './loader.reducer';
import { authentication } from './auth.reducer';

const RootReducer = combineReducers({
    alert,
    authentication,
    loading
});

export default RootReducer;
