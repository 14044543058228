import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { TOKEN_USER } from "../constants";

const isExpired = () => {
    var token = localStorage.getItem(TOKEN_USER);
    if (!token) return true;
    const expiration = jwtDecode(token).exp
    return moment.unix(expiration) - moment(Date.now()) < 30
}

export const jwt = {
    isExpired
}