import { alertActionsType } from '../constants';

const error = (message) => {
    return { type: alertActionsType.ERROR, message };
}

const success = (message) => {
    return { type: alertActionsType.SUCCESS, message };
}

const warn = (message) => {
    return { type: alertActionsType.WARNING, message };
}

const clear = () => {
    return { type: alertActionsType.CLEAR };
}

export const alertActions = {
    error,
    success,
    warn,
    clear
};