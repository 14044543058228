import * as React from 'react';
import Grid from '@mui/material/Grid2';

import { Map, Logout, Settings } from '@mui/icons-material';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

//import packageJson from '../../package.json';

export default function BasicGrid() {
    //const currentYear = new Date().getFullYear();
    const navigate = useNavigate();

    const handleNavigateToMap = () => {
        navigate('/map');
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    return (
        <Box
            sx={{
                backgroundImage: `url('../assets/images/map-background.jpg')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '2rem',
                    left: '2rem',
                }}
            >
                <a href='/' title="Page d'accueil"><img src="../assets/images/logowiic-dark.png" alt="Wiicmenu Logo" width="150" /></a>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: '2rem',
                    right: '2rem',
                }}
            >
                <IconButton
                    color="secondary"
                    onClick={handleLogout}
                    aria-label="logout"
                    title='Se déconnecter'
                >
                    <Logout />
                </IconButton>
            </Box>
            <Box
                spacing={3}
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '16px',
                    textAlign: 'center',
                    padding: 2,
                    maxWidth: 600
                }}
            >
                <Box sx={{ flexGrow: 1, maWidth: 600 }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Bienvenue sur notre application Wiicmenu
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid size={6}>
                            <Button
                                variant="outlined"
                                startIcon={<Map />}
                                fullWidth
                                size="large"
                                onClick={handleNavigateToMap}
                            >
                                Ouvrir la map
                            </Button>
                        </Grid>
                        <Grid size={6}>
                            <Button
                                variant="outlined"
                                startIcon={<Settings />}
                                fullWidth
                                size="large"
                                //onClick={handleNavigateToSettings}
                                disabled
                            >
                                Mes configurations
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            {/* Version number displayed at the bottom */}
            {/* <Box
                sx={{
                    position: 'absolute',
                    bottom: '1rem',
                    width: '100%',
                    textAlign: 'center',
                }}
            >
                <Typography variant="caption" color="textSecondary">
                    {packageJson.version} - © {currentYear} {packageJson.name}. Tous droits réservés.
                </Typography>
            </Box> */}
        </Box>
    );
}
