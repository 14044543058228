import { commonService } from '../generic.service';
//
const getContactsList = (companyId) => {
    return commonService.get(`hubspot/contacts?companyId=${companyId}`);
};

const updateContact = (id, data) => {
    return commonService.patch(`hubspot/contacts/${id}/update`, { data });
};

const createContact = (data) => {
    return commonService.post(`hubspot/contacts`, data);
};

const deleteContact = (id) => {
    return commonService.remove(`hubspot/contacts/${id}`);
};


export const ContactService = {
    getContactsList,
    updateContact,
    createContact,
    deleteContact
};
 