import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Link, Box, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { authActions } from '../redux/actions';
import { useNavigate } from 'react-router-dom';
import { jwt } from '../helpers/jwt';
import packageJson from '../../package.json';

const Login = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const isTokenExpired = jwt.isExpired();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authentication, loading } = useSelector(state => state);

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch(authActions.login(email, password));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    console.log("authentication = ", authentication);
    if (!isTokenExpired && authentication?.user?.token != null) {
      navigate("/home");
    }
  }, [authentication, navigate])

  return (
    <Box
      sx={{
        backgroundImage: `url('../assets/images/map-background.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: 4,
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '2rem',
            left: '2rem',
          }}
        >
          <a href='/' title="Page d'accueil"><img src="../assets/images/logowiic-dark.png" alt="Wiicmenu Logo" width="150" /></a>
        </Box>
        <Typography variant="h5" gutterBottom sx={{ mb: 2, textAlign: 'center', color: '#333' }}>
          Connexion
        </Typography>
        {/* {loading && <CircularProgress sx={{ display: 'block', margin: 'auto', mb: 2 }} />} */}
        <form onSubmit={handleLogin}>
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{ backgroundColor: '#fff', borderRadius: 1, mb: 2 }}
            slotProps={{
              input: {
                readOnly: loading.pending,
              },
            }}
          />
          <TextField
            label="Mot de passe"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    aria-label="toggle password visibility"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              readOnly: loading.pending,
            }}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Link href="/register" variant="body2">
              S'inscrire
            </Link>
            <Link href="/reset-pwd" variant="body2">
              Mot de passe oublié ?
            </Link>
          </Box>
          <Button
            type="submit"
            variant="outlined"
            color="success"
            fullWidth
            sx={{ mt: 3, height: '48px' }}
            startIcon={loading.pending && <CircularProgress size={20} color="inherit" />}
          >
            {!loading.pending && <span>Se connecter</span>}
          </Button>
        </form>
      </Container>

      {/* Footer with version and copyright */}
      <Box
        sx={{
          position: 'absolute',
          bottom: '1rem',
          width: '100%',
          textAlign: 'center',
          color: 'rgba(255, 255, 255, 0.8)'
        }}
      >
        <Typography
          variant="caption"
          sx={{
            fontSize: '0.8rem',
            color: '#000',
            textShadow: '1px 1px 2px rgba(255, 255, 255, 0.5)',
            letterSpacing: '0.06em'
          }}
        >
          {packageJson.version} - © {currentYear} {packageJson.name}. Tous droits réservés.
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
