import { alertActionsType, alertType } from '../constants';

export const alert = (state = {}, action) => {

  switch (action.type) {
    case alertActionsType.ERROR:
      return {
        type: alertType.DANGER,
        message: action?.message
      }; 
    case alertActionsType.WARNING:
      return {
        type: alertType.WARNING,
        message: action?.message
      };     
    case alertActionsType.SUCCESS:
      return {
        type: alertType.SUCCESS,
        message: action?.message
      };              
    case alertActionsType.CLEAR:
      return {};
    default:
      return state;
  }
}