// src/services/generic.service.js
import axiosInstance from "./axiosConfig.js";

const get = (endpoint, filter = null) => {
    return axiosInstance.get(endpoint, { params: filter });
}

const post = (endpoint, data) => {
    return axiosInstance.post(endpoint, data);
}

const patch = (endpoint, data) => {
    return axiosInstance.patch(endpoint, data);
}

const remove = (endpoint) => {
    return axiosInstance.delete(endpoint);
}

const put = (endpoint, data) => {
    return axiosInstance.put(endpoint, data);
}



export const commonService = {
    get,
    post,
    put,
    patch,
    remove,
};
