export const TOKEN_USER = "TOKEN_USER";
export const CURRENT_USER = "CURRENT_USER";

export const alertActionsType = {
    SUCCESS: 'ALERT_SUCCESS',
    WARNING: 'ALERT_WARNING',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};

export const alertType = {
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'error'
};

export const loaderActionsType = {
    PENDING: 'PENDING_LOADER',
    END: 'END_LOADER'
};

export const authActionsType = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'LOGOUT'
};

export const DEFAULT = {
    THRESOLD_SEGMENT: 0.0001
}