import { commonService } from '../generic.service';
const register = (email) => {
    return commonService.post(`users`, { email });
};

const initPassword = (token, password) => {
    return commonService.post(`users/init-password`, { token, password });
};

const resetPassword = (email) => {
    return commonService.post(`users/reset-password`, { email });
};

export const UserService = {
    register,
    initPassword,
    resetPassword
};
