import axios from 'axios';
import { store } from '../redux/store';
import { loaderActionsType, alertActionsType } from "../constants";
import { useNavigate } from 'react-router-dom';

const { REACT_APP_APIURL } = process.env;
const APIURL = REACT_APP_APIURL;

const axiosInstance = axios.create({
    baseURL: APIURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(config => {
    store.dispatch({ type: loaderActionsType.PENDING });
    store.dispatch({ type: alertActionsType.CLEAR });
    if (!config.url.includes("hubspot/email") && !config.url.includes("auth/login")) {
        const token = localStorage.getItem('TOKEN_USER');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
    store.dispatch({ type: loaderActionsType.END });
    return response.data;
}, error => {
    store.dispatch({ type: loaderActionsType.END });
    if (error.response) {
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.pathname('/login');
        } else {
            store.dispatch({ type: alertActionsType.ERROR, messqge: 'fff' });
        }
    } else {
        return Promise.reject(error.toString())
    }
    return Promise.reject(error);
});

export default axiosInstance;
