import { authActionsType } from '../../constants';

const initialState = {};
export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case authActionsType.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user
      };

    case authActionsType.LOGIN_FAILURE:
      return {
        error: action.error
      };

    case authActionsType.LOGOUT:
      return {};
    default:
      return state;
  }
}

