import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getColorForPhase, columnMapping } from '../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faUserPlus, faCheckCircle, faSave, faEdit, faPencilAlt, faTrashAlt, faTimes, faUsers } from '@fortawesome/free-solid-svg-icons';
import { InputAdornment, MenuItem, FormControl, Select, InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, TextField, Typography, FormLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, CircularProgress } from '@mui/material';
import { CompanyService } from "../services/companies/company.service";
import { HubspotService } from "../services/companies/hubspot.service";
import { lifecyclePhases, getOrderForPhase } from "../helpers/utils";
import { updateLifecycleStage, updateConversionDate } from "../services/companies/poi.service";
import { alertActions } from '../redux/actions';
import { fetchAndStoreCompanies } from "../dataaccess/db";
import { ContactService } from "../services/companies/contact.service";

const Popup = ({ feature, handleAfterDelete, handleAfterUpdate }) => {
  const dispatch = useDispatch();
  const restaurantName = feature.properties["name"];
  const address = feature.properties["address"];
  const webSite = feature.properties["site_web"];
  const idForUpdate = feature.properties["id"];

  console.log("idforupdate", idForUpdate);

  const [lifecyclestage, setLifecyclestage] = useState(feature.properties["lifecyclestage"]);
  const [notes, setNotes] = useState(feature.properties["notes_sur_l_inscription"] || "");
  const [selectedOwner, setSelectedOwner] = useState(feature.properties["hubspot_owner_id"] || "");
  const [nombreVisite, setNombreVisite] = useState(feature.properties["nombre_de_visite"] || 0);
  const [contactForEdit, setContactForEdit] = useState(null);
  const [contactForEditId, setContactForEditId] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [loadVisit, setLoadVisit] = useState(false);
  const [loadLifeCycleStage, setLoadLifeCycleStage] = useState(false);
  const [contactToDeleteId, setContactToDeleteId] = useState(null);



  const currentUserToken = localStorage.getItem("TOKEN_USER");
  const lastVisitTimeKey = `${currentUserToken}_${idForUpdate}_lastVisitTime`;
  const lastVisitTime = localStorage.getItem(lastVisitTimeKey) ? new Date(localStorage.getItem(lastVisitTimeKey)) : null;

  // State to manage the confirmation dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [openContactListDialog, setOpenContactListDialog] = useState(false);
  const [contact, setContact] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    jobtitle: '',
    email: '',
  });
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState(new Set());
  const [loading, setLoading] = useState(false);

  const fetchContacts = async () => {
    try {
      const contactsFromCompanyId = await ContactService.getContactsList(idForUpdate);
      setContacts(contactsFromCompanyId.results);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [idForUpdate]);

  useEffect(() => {
    const fetchOwner = async () => {
      if (feature?.properties?.["hubspot_owner_id"]) {
        try {
          const ownerId = feature.properties["hubspot_owner_id"];
          await getOwnerById(ownerId);
        } catch (error) {
          console.error('Erreur lors de la récupération des informations du propriétaire:', error);
        }
      }
    };

    fetchOwner();
  }, [feature]);

  const getOwnerById = async (ownerId) => {
    try {
      const companyOwner = await HubspotService.get(`hubspot/owners/${ownerId}`);
      setSelectedOwner(companyOwner);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations du propriétaire:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (contactForEdit) {
      setContact({
        firstname: contactForEdit.firstname || '',
        lastname: contactForEdit.lastname || '',
        phone: contactForEdit.phone || '',
        email: contactForEdit.email || '',
        jobtitle: contactForEdit.jobtitle || '',
      });
    } else {
      // Si c'est une création, réinitialise le formulaire
      setContact({
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        jobtitle: ''
      });
    }
  }, [contactForEdit]);

  const handleDirectionClick = () => {
    //const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}&hl=fr`;
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${feature.properties['secondseenafterclient']},${feature.properties['firstseenafterclient']}&hl=fr`;
    window.open(googleMapsUrl, "_blank");
  };

  const handleDeleteClick = () => {
    // Open the confirmation dialog
    setOpenDialog(true);
  };

  const handleEditNote = () => {
    setIsEditing(true);
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const confirmDelete = async () => {
    setLoading(true);
    try {
      await CompanyService.deleteCompany(feature.properties["id"]);
      await handleAfterDelete(feature.properties["id"]);
      await fetchAndStoreCompanies();
      dispatch(alertActions.success("Entreprise supprimée !"));
      setLoading(false);
    } catch (error) {
      dispatch(alertActions.error("Suppression échouée !"));
      console.error('Erreur:', error);
      setLoading(false);
    } finally {
      setOpenDialog(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleHubSpotClick = () => {
    const hubSpotUrl = feature.properties["lien_fiche_hubspot"];
    window.open(hubSpotUrl, "_blank");
  };

  const handleVisitClick = () => {
    const visitUrl = feature.properties["Website"];
    window.open(visitUrl, "_blank");
  };


  const handleCloseContactDialog = () => {
    setOpenContactDialog(false);
    setContactForEdit(null);
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContact(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleEditContact = (contactId, contactProperties) => {
    setContactForEditId(contactId);
    setContactForEdit(contactProperties);
    setOpenContactDialog(true); // Ouvre la boîte de dialogue pour l'édition
  };

  const handleCreateContact = () => {
    setContactForEditId(null);
    setContact({
      firstname: '',
      lastname: '',
      phone: '',
      jobtitle: '',
      email: ''
    });
    setOpenContactDialog(true);
  };

  const handleDeleteContact = (contactId) => {
    setContactToDeleteId(contactId); // Stocke l'ID du contact sélectionné
    setOpenConfirmationDialog(true); // Ouvre la boîte de dialogue de confirmation
  };

  const confirmDeleteContact = async () => {
    setOpenConfirmationDialog(false); // Ferme la boîte de dialogue
    try {
      if (contactToDeleteId) {
        // Appelle le service pour supprimer le contact avec l'ID stocké
        await ContactService.deleteContact(contactToDeleteId);

        // Met à jour la liste des contacts en supprimant le contact supprimé
        setContacts(prevContacts => prevContacts.filter(contact => contact.id !== contactToDeleteId));

        await fetchContacts(); // Rafraîchit la liste des contacts après suppression
        dispatch(alertActions.success("Contact supprimé avec succès !"));
      }
    } catch (error) {
      dispatch(alertActions.error("Échec de la suppression du contact."));
    }
  };

  const handleSaveContact = async () => {
    try {
      const { firstname, lastname, phone, email, jobtitle } = contact;
      const webSite = feature.properties["site_web"];
  
      if (contactForEditId) {
        // Mode édition : mettre à jour le contact
        const response = await ContactService.updateContact(contactForEditId, {
          properties: {
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            email: email,
            jobtitle: jobtitle,
            website: webSite,
          },
        });
        setOpenContactDialog(false);
        setSelectedContacts(new Set());
        await fetchContacts();
        dispatch(alertActions.success("Contact modifié avec succès !"));
      } else {
        // Mode création : créer un nouveau contact
        const response = await ContactService.createContact({
          properties: {
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            email: email,
            jobtitle: jobtitle,
            companyId: idForUpdate,
            company: restaurantName,
            website: webSite,
          },
        });
        setOpenContactDialog(false);
        setContacts(prevContacts => [
          ...prevContacts,
          response
        ]);
        dispatch(alertActions.success("Contact enregistré avec succès !"));
      }
    } catch (error) {
      dispatch(alertActions.error("Échec de l'enregistrement du contact."));
      console.error('Erreur:', error);
    }
  };


  const handleCloseContactListDialog = () => {
    setOpenContactListDialog(false);
  };

  const handleMarkVisitClick = async () => {
    setLoadVisit(true);
    const currentTime = new Date();

    if (lastVisitTime) {
      const elapsedTime = (currentTime - new Date(lastVisitTime)) / 1000 / 60; // Temps écoulé en minutes
      const remainingTime = Math.max(0, 30 - Math.floor(elapsedTime)); // Temps restant en minutes
      if (remainingTime > 0) {
        dispatch(alertActions.error(`Veuillez attendre encore ${remainingTime} minute${remainingTime > 1 ? 's' : ''} avant d'enregistrer une nouvelle visite.`));
        setLoadVisit(false);
        return;
      }
    }
    try {
      // Incrémente le nombre de visites et définit le timestamp de la dernière visite
      const updatedNombreVisite = parseInt(nombreVisite) + 1;
      const updatedLastVisiteTimestamp = new Date();
      // Met à jour les états locaux
      setNombreVisite(nombreVisite + 1);
      localStorage.setItem(lastVisitTimeKey, updatedLastVisiteTimestamp.toISOString());

      // Met à jour le nombre de visites et le timestamp dans HubSpot et l'API

      const hubspotResponse = await HubspotService.updateNombreVisite(idForUpdate, updatedNombreVisite, lifecyclestage);
      const apiResponse = await CompanyService.updateNombreVisite(idForUpdate, hubspotResponse.properties.nombre_de_visite);

      feature.properties['nombre_de_visite'] = hubspotResponse.properties.nombre_de_visite;
      setNombreVisite(hubspotResponse.properties.nombre_de_visite);

      // Met à jour le timestamp de la dernière visite dans HubSpot et l'API
      const hubspotResponseLastVisit = await HubspotService.updateLastVisiteTimestamp(idForUpdate, updatedLastVisiteTimestamp);
      const apiResponseLastVisit = await CompanyService.updateLastVisiteTimestamp(idForUpdate, updatedLastVisiteTimestamp);

      // Met à jour les propriétés de la feature avec les données retournées
      feature.properties['lastvisit'] = hubspotResponseLastVisit.properties.lastvisit;

      // Affiche un message de succès
      await await getOwnerById('current_id');
      dispatch(alertActions.success("Visite enregistrée !"));

      setLoadVisit(false);
    } catch (error) {
      // Affiche un message d'erreur en cas de problème
      setLoadVisit(false);
      dispatch(alertActions.error("Enregistrement échoué !"));
    }
  };

  const isAnyRelevantFieldFilled = () => {
    return contact.firstname || contact.lastname || contact.email || contact.phone;
  };

  const handleLifecyclestageChange = async (event) => {
    setLoadLifeCycleStage(true);
    const currentTime = new Date();
    const newPhase = event.target.value;
    try {
      const hubspotResponse = await HubspotService.updateLifecyclestage(idForUpdate, newPhase, lifecyclestage);
      const apiResponse = await CompanyService.updateLifecyclePhase(idForUpdate, hubspotResponse.properties.lifecyclestage, lifecyclestage);
      if (apiResponse && (lastVisitTime === null || (currentTime - lastVisitTime >= 30 * 60 * 1000))) {
        await handleMarkVisitClick();
      }
      else {
        const updatedLastVisiteTimestamp = new Date();
        const hubspotResponseLastVisit = await HubspotService.updateLastVisiteTimestamp(idForUpdate, updatedLastVisiteTimestamp);
        const apiResponseLastVisit = await CompanyService.updateLastVisiteTimestamp(idForUpdate, updatedLastVisiteTimestamp);
        feature.properties['lastvisit'] = hubspotResponseLastVisit.properties.lastvisit;
      }
      await updateLifecycleStage(idForUpdate, hubspotResponse.properties.lifecyclestage);

      const columnName = columnMapping[lifecyclePhases.find(phase => phase.value === newPhase).label];
      if (columnName) {
        const currentDate = new Date();
        currentDate.setUTCHours(0, 0, 0, 0);
        const formattedDate = currentDate.toISOString();
        await HubspotService.updateConversionDate(idForUpdate, columnName, formattedDate);
        await CompanyService.updateConversionDate(idForUpdate, columnName, formattedDate);
        await updateConversionDate(idForUpdate, columnName, new Date())
      }

      feature.properties['lifecyclestage'] = hubspotResponse.properties.lifecyclestage;
      setLifecyclestage(apiResponse.lifecyclestage);

      await getOwnerById('current_id');
      await handleAfterUpdate();
      dispatch(alertActions.success("Phase de cycle de vie enregistrée !"));
      setLoadLifeCycleStage(false);
    } catch (error) {
      console.error('Erreur:', error);
      dispatch(alertActions.error("Enregistrement échoué !"));
      setLoadLifeCycleStage(false);
    }
  };

  const handleSaveNote = async (event) => {
    try {
      feature.properties["notes_sur_l_inscription"] = notes;
      const hubspotResponse = await HubspotService.updateNotes(idForUpdate, notes);
      const apiResponse = await CompanyService.updateNotes(idForUpdate, notes);
      setNotes(notes);
      setIsEditing(false);
      dispatch(alertActions.success("Note enregistrée !"));
    } catch (error) {
      setIsEditing(false);
      dispatch(alertActions.success("Enregistrement échoué"));
      console.error('Erreur:', error);
    }
  };

  const handleShowContactList = () => {
    setOpenContactListDialog(true);
  };

  const handleDigitalizationClick = async () => {

    const token = await HubspotService.getToken();
    const encryptedEmail = token.encryptedEmail
    const recordId = feature.properties["id"];
    const city = feature.properties["city"];
    const restaurantName = feature.properties["name"];
    const postalCode = feature.properties["zip"];
    const address = feature.properties["address"];
    const url = `https://wiicmenu-qrcode.com/Admin/dist/creat-cloud.php?record=${encodeURIComponent(recordId)}&ville=${encodeURIComponent(city)}&nom_restaurant=${encodeURIComponent(restaurantName)}&code_postal=${encodeURIComponent(postalCode)}&adresse=${encodeURIComponent(address)}&token=${encryptedEmail}`;
    window.open(url, '_blank');

    //Mettre à jour le cyle de vie
    setLoadLifeCycleStage(true);
    const newPhase = "opportunity";
    const currentTime = new Date();
    const newDate = currentTime;
    const hubspotResponse = await HubspotService.updateLifecyclestage(idForUpdate, newPhase, lifecyclestage);
    const apiResponse = await CompanyService.updateLifecyclePhase(idForUpdate, hubspotResponse.properties.lifecyclestage, lifecyclestage);
    if (apiResponse && (lastVisitTime === null || (currentTime - lastVisitTime >= 30 * 60 * 1000))) {
      await handleMarkVisitClick();
    }
    await updateLifecycleStage(idForUpdate, hubspotResponse.properties.lifecyclestage);
    setLoadLifeCycleStage(false);
    setLifecyclestage(apiResponse.lifecyclestage);

    currentTime.setUTCHours(0, 0, 0, 0);
    const formattedDate = currentTime.toISOString();
    await HubspotService.updateConversionDate(idForUpdate, "date_de_conversion_en_accord", formattedDate);
    await CompanyService.updateConversionDate(idForUpdate, "date_de_conversion_en_accord", formattedDate);
    await updateConversionDate(idForUpdate, "date_de_conversion_en_accord", newDate);

  };

  return (
    <div id="popup-container">
      {/* <h3><strong>{restaurantName}</strong></h3> */}
      <h4><strong>{address}</strong></h4>

      <Box mt={2} mb={2}>
        <FormControl fullWidth>
          <FormLabel sx={{ marginBottom: '4px', fontSize: '12px', fontWeight: 'bold' }}>
            Propriétaire
          </FormLabel>
          <Typography
            variant="body1"
            sx={{
              padding: '12px 16px',
              fontSize: '16px',
              fontWeight: '500',
              backgroundColor: '#f5f5f5',
              borderRadius: '4px',
            }}
          >
            {selectedOwner && (
              `${selectedOwner.firstName || ''} ${selectedOwner.lastName || ''}`.trim() ||
              'Propriétaire'
            )}
          </Typography>
        </FormControl>
      </Box>

      <p>
        {isEditing ? (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label="Note sur l'inscription"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              multiline
              fullWidth
              variant="outlined"
              rows={2}
              InputProps={{
                style: { fontSize: '0.775rem' }
              }}
            />
            <FontAwesomeIcon
              icon={faSave}
              className="icon"
              size="lg"
              onClick={handleSaveNote}
              title="Sauvegarder"
              style={{ cursor: 'pointer', marginLeft: '10px' }}
            />
          </span>
        ) : (
          <>
            <strong>Note sur l'inscription&nbsp;: </strong>
            <span>
              {isExpanded ? (
                notes
              ) : (
                <>
                  {notes.slice(0, 20)}
                  {notes.length > 20 && '...'}
                </>
              )}
              {notes.length > 20 && (
                <span
                  onClick={handleToggleExpand}
                  style={{ color: 'blue', cursor: 'pointer', marginLeft: '10px' }}>
                  {isExpanded ? "Voir moins" : "Voir plus"}
                </span>
              )}
              <FontAwesomeIcon
                icon={faEdit}
                className="icon"
                size="lg"
                onClick={handleEditNote}
                title="Modifier"
                style={{ cursor: 'pointer', marginLeft: '10px' }}
              />
            </span>
          </>
        )}
      </p>

      <Box mt={2} mb={2}>
        <FormControl fullWidth>
          <InputLabel>Phase de cycle de vie</InputLabel>
          <Select
            value={lifecyclestage}
            label="Phase de cycle de vie"
            onChange={handleLifecyclestageChange}
            style={{ color: getColorForPhase(lifecyclestage) }}
            IconComponent={loadLifeCycleStage ? () => null : undefined}
            endAdornment={
              loadLifeCycleStage && (
                <InputAdornment position="end">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <CircularProgress size={20} />
                  </div>
                </InputAdornment>
              )
            }
          >
            {lifecyclePhases
              .filter(phase => phase.order >= getOrderForPhase(lifecyclestage))
              .map(phase => (
                <MenuItem key={phase.value} value={phase.value}>
                  <span style={{
                    display: 'inline-block',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: phase.color,
                    marginRight: '8px'
                  }}></span>
                  {phase.label}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>

      <div className="button-container">
        <div className="icon-button" onClick={handleDirectionClick} title="Ouvrir la direction dans Google Maps">
          <img
            src="/assets/images/Google-icon-logo.png"
            alt="HubSpot Logo"
            style={{ width: '20px', height: '20px' }}
          />
        </div>
        <div className="icon-button" onClick={handleHubSpotClick} title="Ouvrir la fiche HubSpot">
          <img
            src="/assets/images/Hubspot-icon-logo.png"
            alt="HubSpot Logo"
            style={{ width: '20px', height: '20px' }}
          />
        </div>
        {webSite &&
          <div className="icon-button" onClick={handleVisitClick} title="Visiter le site web">
            <FontAwesomeIcon icon={faExternalLinkAlt} size="lg" />
          </div>
        }
        {/* Nouveau bouton "Créer un contact" */}
        <div className="icon-button" onClick={handleShowContactList} title="Créer un contact">
          <FontAwesomeIcon icon={faUsers} size="lg" />
        </div>

        {/* Dialogue pour afficher la liste des contacts */}
        <Dialog
          open={openContactListDialog}
          onClose={handleCloseContactListDialog}
          PaperProps={{ style: { borderRadius: 5 } }}
          maxWidth="md"
          fullWidth
          sx={{
            '& .MuiDialog-paper': {
              width: '600px',
              height: '400px',
              maxWidth: '100%',
            },
          }}
        >
          <DialogTitle style={{ textAlign: 'center' }}>
            Liste des Contacts
            <IconButton
              style={{ position: 'absolute', right: 8, top: 8 }}
              onClick={handleCloseContactListDialog}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Button
                          variant="outlined"
                          size="small"
                          style={{
                            marginLeft: '-10px', // Spacing from the left edge
                            padding: '0', // Remove extra padding around the icon
                            width: '30px', // Control the button's size
                            height: '30px',
                            border: '1px solid', // Defined border
                            borderRadius: '50%', // Circular shape
                            minWidth: 'unset', // Prevent the button from expanding
                            display: 'flex', // Make button a flex container to center the icon
                            justifyContent: 'center', // Center the icon horizontally
                            alignItems: 'center', // Center the icon vertically
                          }}
                          onClick={handleCreateContact}
                          title="Ajouter un Contact"
                        >
                          <FontAwesomeIcon icon={faUserPlus} style={{ fontSize: '14px' }} />
                        </Button>
                      </div>
                    </TableCell>
                    <TableCell>Prénom</TableCell>
                    <TableCell>Nom</TableCell>
                    <TableCell>Téléphone</TableCell>
                    <TableCell>Poste</TableCell>
                    {/* <TableCell>E-mail</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts.map((contact, index) => (
                    <TableRow key={index}>
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedContacts.has(index)}
                          onChange={() => toggleContactSelection(index)}
                        />
                      </TableCell> */}
                      <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                          <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            style={{
                              marginLeft: '-15px',
                              padding: '0',
                              width: '30px',
                              height: '30px',
                              border: 'none',
                              borderRadius: '50%',
                              minWidth: 'unset',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginRight: '2px' // Space between the two buttons
                            }}
                            onClick={() => handleDeleteContact(contact.id)}
                            title="Suppression des Contacts"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '14px' }} />
                          </Button>

                          <Button
                            variant="outlined"
                            size="small"
                            style={{
                              marginLeft: '0px',
                              padding: '0',
                              width: '30px',
                              height: '30px',
                              border: 'none',
                              borderRadius: '50%',
                              minWidth: 'unset',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                            onClick={() => handleEditContact(contact.id, contact.properties)}
                            title="Modifier un Contact"
                          >
                            <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: '14px' }} />
                          </Button>
                        </div>
                      </TableCell>
                      <TableCell>{contact.properties.firstname}</TableCell>
                      <TableCell>{contact.properties.lastname}</TableCell>
                      <TableCell>{contact.properties.phone}</TableCell>
                      <TableCell>{contact.properties.jobtitle}</TableCell>
                      {/* <TableCell>{contact.properties.email}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>

        {/* Dialogue pour créer un nouveau contact */}
        <Dialog
          open={openContactDialog}
          onClose={handleCloseContactDialog}
          maxWidth="xs"
          PaperProps={{ style: { borderRadius: 5 } }} // Style du dialogue
        >
          <DialogTitle style={{ textAlign: 'center' }}>
            {contactForEdit ? 'Modifier le Contact' : 'Créer un Contact'}
          </DialogTitle>

          <DialogContent>
            <TextField
              label="Prénom"
              name="firstname"
              variant="outlined"
              value={contact.firstname}
              sx={{ mt: 1 }}
              onChange={handleContactChange}
              fullWidth
              InputProps={{
                style: { height: '54px' }
              }}
            />

            <TextField
              label="Nom"
              name="lastname"
              variant="outlined"
              value={contact.lastname}
              sx={{ mt: 1 }}
              onChange={handleContactChange}
              fullWidth
              InputProps={{
                style: { height: '54px' }
              }}
            />

            <TextField
              label="Téléphone"
              name="phone"
              variant="outlined"
              value={contact.phone}
              sx={{ mt: 1 }}
              onChange={handleContactChange}
              fullWidth
              InputProps={{
                style: { height: '54px' }
              }}
            />

            <TextField
              label="E-mail"
              name="email"
              variant="outlined"
              value={contact.email}
              sx={{ mt: 1 }}
              onChange={handleContactChange}
              fullWidth
              InputProps={{
                style: { height: '54px' }
              }}
            />

            <TextField
              label="Poste"
              name="jobtitle"
              variant="outlined"
              value={contact.jobtitle}
              sx={{ mt: 1 }}
              onChange={handleContactChange}
              fullWidth
              InputProps={{
                style: { height: '54px' }
              }}
            />

            <DialogActions style={{ justifyContent: 'center', marginTop: 15, marginBottom: 0 }}>
              <Button onClick={handleCloseContactDialog} color="warning" variant="outlined" style={{ width: 150 }}>
                Annuler
              </Button>
              <Button
                onClick={handleSaveContact}
                color="primary"
                variant="outlined"
                style={{ width: 150 }}
                disabled={!isAnyRelevantFieldFilled()}
              >
                Enregistrer
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        {/* Nouveau bouton "Visiter" */}
        <div className="icon-button" onClick={handleMarkVisitClick} title="Visiter">
          {!loadVisit && <FontAwesomeIcon icon={faCheckCircle} size="lg" />}
          {loadVisit && <CircularProgress size={20} color="inherit" />}
        </div>
      </div>

      <Button
        color="success"
        variant="outlined"
        fullWidth
        onClick={handleDigitalizationClick}
        sx={{ mt: 1, height: '36px' }}
      >
        <span>Accord de digitalisation</span>
      </Button>

      <Button
        color="error"
        variant="outlined"
        fullWidth
        onClick={handleDeleteClick}
        sx={{ mt: 1, height: '36px' }}
      >
        <span>Supprimer</span>
      </Button>

      <Dialog
        maxWidth="xs"
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        PaperProps={{ style: { borderRadius: 10, padding: '15px 0' } }}
      >
        <DialogTitle style={{ padding: 0, textAlign: 'center' }}>Confirmation</DialogTitle>
        <DialogContent style={{ padding: 0, textAlign: 'center' }}>
          <DialogContentText style={{ padding: 0, textAlign: 'center' }}>
            <p>Êtes-vous sûr de vouloir supprimer cette entreprise ?<br />Cette action est irreversible.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: 0, justifyContent: 'center' }}>
          <Button onClick={handleCloseDialog} color="error" variant="outlined" style={{ width: 150 }}>
            Non
          </Button>
          <Button
            variant="outlined"
            color="success"
            fullWidth
            onClick={confirmDelete}
            sx={{ width: 150 }}
            startIcon={loading && <CircularProgress size={20} color="inherit" />}
          >
            {!loading && <span>Oui</span>}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        PaperProps={{ style: { borderRadius: 5 } }}
      >
        <DialogTitle>Confirmation de la Suppression</DialogTitle>
        <DialogContent>
          <Typography>Êtes-vous sûr de vouloir supprimer les contacts sélectionnés ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmationDialog(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={confirmDeleteContact} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Popup;
